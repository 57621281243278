<template>
    <b-modal
            v-model="show"
            centered
            size="xl"
            ok-only
            no-close-on-backdrop
            no-close-on-esc
    >
        <template #modal-header>
            <b-container>
                <b-row>
                    <b-col v-if="isNotNull(demo)">
                        <h5>Changelog for {{demo.name}}</h5>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col v-if="isNotNull(currentVersion)">
                        <span>Published {{formatDate(currentVersion.dateInsert)}}</span><b-badge pill variant="secondary" v-if="latest" class="mx-2">Latest</b-badge>
                        <br />
                        <span>Version code : {{currentVersion.versionCode}}</span>
                        <br />
                        <span>Version name : {{currentVersion.versionName}}</span>
                    </b-col>
                </b-row>
            </b-container>
        </template>

        <div class="modal-custom">
            <VueTrumbowyg
                    v-if="editMode"
                    :defaultValue="changelog"
                    @onChange="onChangeChangelog"
                    :key="vueTrumbowygKey">
            </VueTrumbowyg>

            <p v-else>
                <span v-if="isNotNull(changelog)" v-html="changelog"></span>
                <span v-else>No changelog for this version</span>
            </p>

            <b-alert :variant="message.variant" :show="isNotNull(message.content)" class="mt-3">{{ message.content }}</b-alert>
        </div>

        <template #modal-footer>
            <div class="modal-custom">
                <b-button variant="warning" @click="editMode = true" v-if="!editMode">
                    Edit
                </b-button>
                <b-button variant="dark" @click="close" style="float: right" v-if="!editMode">
                    OK
                </b-button>
                <b-button variant="dark" @click="save" style="float: right" v-if="editMode">
                    Save
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
	import DemonstrationsApi from "@/assets/js/DemonstrationsApi";
	import VueTrumbowyg from "../tools/VueTrumbowyg";
	import moment from 'moment';
	import 'moment/locale/fr';
	moment().locale('fr');

	export default {
		name: "ChangelogModal",
		props: {
			show: Boolean,
			demo: Object,
			version: Object,
			latest: Boolean,
			onlyEdit: Boolean
		},
		components: {VueTrumbowyg},
		data(){
			return {
				editMode: false,
				changelog: "",
				currentVersion: null,
				message: {},
				vueTrumbowygKey: 0
			}
		},
		created(){
			if(this.isNotNull(this.version)){
				this.currentVersion = this.version;
				this.changelog = this.version.changeLog;
			}else{
				this.getLastUploadedVersion();
			}

			if(this.onlyEdit){
				this.editMode = true
			}
		},
		methods:{
			onChangeChangelog(val){
				this.changelog = val
			},
			getLastUploadedVersion(){
				if(this.isNotNull(this.demo) && this.isNotNull(this.demo.versions)){
					this.currentVersion = this.demo.versions[0];
					this.changelog = this.currentVersion.changeLog;
					this.demo.versions.forEach(version => {
						if(moment(version.dateInsert, "DD/MM/YYYY HH:mm").isAfter(moment(this.currentVersion.dateInsert, "DD/MM/YYYY HH:mm"))){
							this.currentVersion = version;
							this.changelog = version.changeLog;
							this.vueTrumbowygKey++;
						}
					}, this)
				}
			},
			save(){
				DemonstrationsApi.updateVersion(this.currentVersion.id, this.changelog)
					.then(response => {
						if(!response.data.error){
							if(this.onlyEdit){
								this.$emit('closeChangelogModal')
							}else{
								this.editMode = false
							}
						}
					})
					.catch(e => {
						console.log(e);
						this.message = {
							variant: "danger",
							content: "Error while saving changelog"
						};
						setTimeout(() => {
							this.message.content = null
						}, 5000);
					});
			},
			close(){
				this.$emit('closeChangelogModal')
			}
		},
		watch:{
			demo(){
				this.getLastUploadedVersion()
			}
		}
	}
</script>

<style>
</style>