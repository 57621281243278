<template>
    <div id="VueTrumbowyg" style="color: #000;">
        <trumbowyg
                v-model="content"
                :config="config">
        </trumbowyg>
    </div>
</template>

<script>
	import Trumbowyg from 'vue-trumbowyg';
	import 'trumbowyg/dist/ui/trumbowyg.css';
	import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
	import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
	import 'trumbowyg/dist/plugins/indent/trumbowyg.indent.js'
	import 'trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize.js'
	export default {
		name: "VueTrumbowyg",
		props:{
			defaultValue: String
		},
		components: {
			Trumbowyg
		},
		data() {
			return {
				content: null,
				config: {
					autogrow: false,
					btns: [
						/*['undo', 'redo'],*/
						['bold', 'italic', 'underline'],
						['fontsize', 'foreColor'],
						['unorderedList', 'orderedList'],
						['indent', 'outdent']
					],
					plugins: {
						fontsize: {
							sizeList: [
								'15px',
								'20px',
								'25px',
								'30px',
							],
							allowCustomSize: false
						},
						colors: {
							colorList: [
								'000','3d3d3d','ff0000','28a745','fd7e14'
							]
						}
					}
				}
			};
		},
		created(){
			this.content = this.defaultValue
		},
		watch:{
			content(val){
				this.$emit('onChange', val)
			}
		}
	}
</script>

<style scoped>

</style>